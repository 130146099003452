import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import '../index.css'
import image1 from '../imges/FoodFactory.webp';
import image2 from '../imges/cookies.webp';
import image3 from '../imges/market.webp'
import imagesignup from '../imges/woman conducting a pasta sauce demonstration at a supermarket. The woman_ enthusiastic and professional_ st.jpg'
import logo from '../imges/Gpt4foods-Logo-NBG-White.png'

export default function Body(props) {
  const handleRecaptchaChange = (value) => {
    console.log('reCAPTCHA value:', value);
  };
  return (
    <>
      <div class="container-fluid my-5 row featurette border-top py-5">
        <div class="col-md-7 px-5 py-5">
          <h2 class="featurette-heading display-4 fw-bold lh-1">We're pioneering the food industry's future</h2>
          <p class="lead fw-bold">We're pioneering the food industry's future with our AI-powered e-commerce platform, designed to seamlessly integrate with our machine learning bot, ensuring a state-of-the-art experience<br/> for small vendors.</p>
          <div class="my-5 py-3"></div>
          <h2 class="featurette-heading display-6 fw-bold lh-1">Request Your Invitation Before Launch</h2>
          <p class="lead fw-bold">Get 6 months of subscription free <br />For all categories present at expo west</p>
          <div class="my-5 py-3"></div>
          <span class="lead fw-bold text-danger py-5 my-5">Beta Launch Date : August/September 2024</span>
        </div>

        <div class="col-md-5">
          <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto py-5" width="500" height="500" src={image1} alt="AI-powered e-commerce" />

        </div>
      </div>
      <div class="album py-5 border-top my-5">
        <div class="container my-5 py-5">

          <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
            <div class="col">
              <div>
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={image2} alt="AI-powered e-commerce" />
                <div class="card-body py-5">
                  <p class="lead card-text text-white fw-bold">Exclusive Offer for Early Sign-ups <br /> Sign Up Now To Get Your Invite</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <a href="#SignUpForm"><button type="button" class="btn btn-sm btn-outline-light">Sign-up &#8599;</button></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div>
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={image3} alt="AI-powered e-commerce" />
                <div class="card-body py-5">
                  <p class="lead card-text text-white fw-bold">Your gateway to an AI-powered food marketplace.Easily reach buyers.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">Learn More &#8599;</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col">
              <div>
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto px-5" width="500" height="500" src={logo} alt="AI-powered e-commerce" />
                <br/><br/><br/><br/><br/><br/><br/>
                <div class="card-body py-5 px-5">
                  <p class="lead card-text text-white fw-bold">Revolutionize Your  Business with AI's Magic!<br />Grow Faster and save money with our exclusive AI-powered tools.</p>
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="btn-group">
                      <button type="button" class="btn btn-sm btn-outline-light" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">Learn More &#8599;</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section id="SignUpForm" aria-label="Subscribe example">
                <div className="container py-5 border-top">
                  <article className="row justify-content-center align-items-center">
                  <div className="col-md-6">
                      <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={imagesignup} alt="AI-powered e-commerce" />
                    </div>
                    <div className="col-md-5 mx-5 px-5">
                      <hgroup>
                        <h2 class="text-white">Claim Your Exclusive</h2>
                        <h3 class="text-white">Invitation Now!</h3>
                      </hgroup>
                      <form>
                        <div class="mb-3">
                          <label for="YourName" class="form-label text-white fw-bold">Your Name</label>
                          <input type="text" class="form-control" id="YourName" name="Name" required />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label text-white fw-bold">Email address</label>
                          <input type="email" class="form-control" id="exampleInputEmail1" name="Email" aria-describedby="emailHelp" required />
                          <div id="emailHelp" class="form-text text-white fw-bold">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                          <label for="CompanyName" class="form-label text-white fw-bold">Company Name</label>
                          <input type="text" class="form-control" id="CompanyName" name="CompanyName" aria-describedby="CompanyNameHelp" required />
                        </div>

                        <div class="mb-3">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                            <label class="form-check-label fw-bold" for="inlineRadio1">Manufacturer</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                            <label class="form-check-label fw-bold" for="inlineRadio2">Vendor</label>
                          </div>
                        </div>

                        <ReCAPTCHA
                          sitekey="6LeJLqspAAAAAAe_Grdq3D_OsZpf8FVpVSZ_Yc20"
                          onChange={handleRecaptchaChange}
                        />
                        <button type="submit" class="btn btn-md btn-outline-light my-4">Submit</button>
                      </form>
                    </div>
                    
                  </article>
                </div>
              </section>



    </>

  )
}
