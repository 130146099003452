import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import video1 from '../imges/video/Gpt4foodsHeroBannerVideo.mp4';
import logo from '../imges/Gpt4foods-Logo-NBG-White.png';
import logo2 from '../imges/Gpt4foods-Logo-NBG -black.png'
import image00 from '../imges/NPEW Booth Photo - Hammons.jpg'
import image01 from '../imges/vision.jpg'
import Piati from '../imges/Piati.png'
import imagesignup from '../imges/woman conducting a pasta sauce demonstration at a supermarket. The woman_ enthusiastic and professional_ st.jpg'
import '../HeroBanner.css';
const HeroBanner = (props) => {
  const handleRecaptchaChange = (value) => {
    console.log('reCAPTCHA value:', value);
  };
  
  return (
    <div id="Bero-Banner" class="hero-banner">
      <video autoPlay muted loop>
        <source src={video1} type="video/mp4" />
        {/* Add additional source elements for different video formats if needed */}
        Your browser does not support the video tag.
      </video>
      <div class="content">
        <header>
          <div class="container">
            <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <span class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
              <a href="#Bero-Banner"><img src={logo} alt="Logo" width="120px"  class="img-fluid cursor" /></a>
              </span>

              <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0 px-5">
              </ul>

              <div class="text-end">
                <button type="button" class="btn btn-sm btn-outline-light me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">About &#8599;</button>
                <a href="#SignUpForm"><button type="button" class="btn btn-sm btn-outline-light">Sign-up &#8599;</button></a>
              </div>
            </div>
          </div>
        </header>
        <div class="p-5 mb-4 rounded-3 text-start px-5">
          <div class="container-fluid py-5 banner">
            <h1 class="display-1 fw-bold">Unleash AI's Magic for Success !</h1>
            <p class="display-6 text-white fw-bold">Empowering Small Food and Nutrition Vendors to Grow Faster</p>
            <button class="btn btn-sm btn-outline-light btn-lg my-5 fw-bold" data-bs-toggle="modal" data-bs-target="#staticBackdrop1" type="button">learn more about GPT4foods &#8599;</button>
          </div>
        </div>
      </div>
      {/* ABOUT Modal */}
      <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-fullscreen">
          <div class="modal-content main text-white">
            <div class="modal-header d-flex justify-content-between align-items-center">
              <img
                src={logo2}
                width="120px"
                id="image-logo"
                class="img-fluid cursor"
                alt="AI-powered e-commerce"
                data-bs-dismiss="modal" aria-label="Close"
              />
              <div class="display-6 text-center text-black fw-bold flex-grow-1"><u>About</u></div>
              <button type="button" class="btn-close bg-light" data-bs-dismiss="modal" aria-label="Close"></button>

            </div>

            <div class="modal-body">
              <div class="px-4 py-5">
                <div class="py-5">
                  <div class="col-lg-6 mx-auto">
                    <span class="display-6 fw-bold">GPT4Foods: </span><span class="leadB fw-bold">Where AI Revolutionizes E-Commerce</span>
                    <p class="leadC"><i>Discover the future of online shopping with our cutting-edge platform. </i><br /></p>
                    <p class="leadD">We’ve seamlessly integrated powerful E-Commerce features into our AI-driven ecosystem, creating an unparalleled experience for users. From personalized recommendations to streamlined checkout processes, GPT4Foods is redefining how you shop online.<br /></p>
                    <p class="my-5 leadD"><i class="fw-bold leadB">Our Mission?</i> To equip and empower both small vendors and visitors with a comprehensive suite of industry-specific AI tools — all conveniently accessible in one place. </p>

                  </div>
                </div>
              </div>
              <div class="overflow-hidden">
                <div>
                  <img src={image00} class="img-fluid border rounded-3 shadow-lg mb-4" alt="Gpt4Foods AI-powered e-commerce" width="100%" height="100%" loading="lazy" />
                </div>
              </div>
              <div class="container-fluid my-5 row featurette border-top border-bottom py-5">
                <div class="col-md-7 px-5 py-5">
                  <h2 class="featurette-heading display-4 fw-bold lh-1">Our Vision</h2>
                  <p class="lead fw-bold my-4">As AI contineus to work it's magic and advance rapidly , we're committed to leveling the playing field for small vendors in today's fiercely competitive market.  </p>
                </div>

                <div class="col-md-5">
                  <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto py-5" width="500" height="500" src={image01} alt="AI-powered e-commerce" />

                </div>
              </div>






              <section id="SignUpForm2" aria-label="Subscribe example">
                <div class="container py-5">
                  <article class="row justify-content-center align-items-center">
                  <div class="col-md-6">
                      <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={imagesignup} alt="AI-powered e-commerce" />
                    </div>
                    <div class="col-md-5 mx-5 px-5">
                      <hgroup>
                        <h2 class="text-white">Claim Your Exclusive</h2>
                        <h3 class="text-white">Invitation Now!</h3>
                      </hgroup>
                      <form>
                        <div class="mb-3">
                          <label for="YourName" class="form-label text-white fw-bold">Your Name</label>
                          <input type="text" class="form-control" id="YourName" name="Name" required />
                        </div>
                        <div class="mb-3">
                          <label for="exampleInputEmail1" class="form-label text-white fw-bold">Email address</label>
                          <input type="email" class="form-control" id="exampleInputEmail1" name="Email"  aria-describedby="emailHelp" required />
                          <div id="emailHelp" class="form-text text-white fw-bold">We'll never share your email with anyone else.</div>
                        </div>
                        <div class="mb-3">
                          <label for="CompanyName" class="form-label text-white fw-bold">Company Name</label>
                          <input type="text" class="form-control" id="CompanyName" name="CompanyName" aria-describedby="CompanyNameHelp" required />
                        </div>

                        <div class="mb-3">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                            <label class="form-check-label fw-bold" for="inlineRadio1">Manufacturer</label>
                          </div>
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                            <label class="form-check-label fw-bold" for="inlineRadio2">Vendor</label>
                          </div>
                        </div>

                        <ReCAPTCHA
                          sitekey="6LeJLqspAAAAAAe_Grdq3D_OsZpf8FVpVSZ_Yc20"
                          onChange={handleRecaptchaChange}
                        />
                        <button type="submit" class="btn btn-md btn-outline-light my-4">Submit</button>
                      </form>
                    </div>
                    
                  </article>
                </div>
              </section>



              <div class="container-fluid row featurette border-top border-bottom py-5">
                <div class="col-md-5">
                  <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={Piati} alt="AI-powered e-commerce" />

                </div>
                <div class="col-md-7 px-5 py-5">
                  <h2 class="featurette-heading display-6 fw-bold lh-1 py-5">Introducing Piati: <span class="leadA fw-bold">Your AI Business Ally </span></h2>
                  <p class="lead marginA">
                    <i >" Hello! I’m Piati, your trusted AI business partner.<br/> As part of the GPT4Foods team,  I’m here to collaborate with you and assist in every aspect of your business journey.<br/>                     With my e-commerce capabilities, you’ll unlock the tools to elevate your business     <br/>                                 And compete seamlessly with industry giants. <br/>And guess what?<br/> I’ve got insights                                                                 into exactly what your customers desire.                                                                                                  Let’s make your business thrive!"</i>
                  </p>
                </div>
              </div>
              <div class="col-md-5">
                <img class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" src={logo} alt="AI-powered e-commerce" />

              </div>


            </div>
          </div>
        </div>
      </div>


    </div>



  
  )
}

export default HeroBanner
