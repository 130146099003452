import React from 'react'
import '../index.css'
import logo from '../imges/Gpt4foods-Logo-NBG-White.png'
import '../Footer.css'
export default function Footer() {
  return (
    <div>
      <footer className="bg-black">
        <div
          className="d-flex flex-column flex-sm-row justify-content-between border rounded-3 px-2 py-2 text-white"
        > 
          <a href="#Bero-Banner">
          <img
            src={logo}
            width="120px"
            id="image-logo"
            class="img-fluid cursor" 
            alt="AI-powered e-commerce"
          />
          </a>
          <div className="col-md-4 d-flex align-items-center justify-content-center">
            <ul className="list-unstyled small text-white">
              <li className="mb-2">
                <span className="text-decoration-none text-white fw-bold cursor" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">About | </span>
                <span className="text-decoration-none text-white fw-bold cursor" data-bs-toggle="modal" data-bs-target="#staticBackdrop2">Terms and Policy | </span>
                <span className="text-decoration-none text-white fw-bold cursor" data-bs-toggle="modal" data-bs-target="#staticBackdrop3">Privacy Policy  </span>
              </li>
            </ul>
          </div>

          <div className="social-media">
          <div class="text-end py-3">
                <button type="button" class="btn btn-sm btn-light me-2" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">About &#8599;</button>
                <a href="#Bero-Banner"><button type="button" class="btn btn-sm btn-light">Go Above &#8599;</button></a>
              </div>
          </div>
        </div>
      </footer>




      




      {/*TERMS & POLICIES Modal */}
      <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content black">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Gpt4Foods Terms and Policy</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h1 className="display-1 fw-bold">Terms and Policy</h1>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>




      {/*PRIVACY POLICY Modal */}
      <div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content black">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="staticBackdropLabel">Gpt4Foods Privacy Policy</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <h1 className="display-1 fw-bold">Privacy Policy</h1>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
