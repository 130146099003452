import React from 'react'
import './App.css';
import HeroBanner from './MyComponents/HeroBanner';
import Body from './MyComponents/Body';
import Footer from './MyComponents/Footer'




function App() {
  return (
   <>
   <HeroBanner/>
   <Body/>
   <Footer/>
   </>
  );
}

export default App;
